import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';
import Seo from '../components/seo';
import '../styles/style.sass';

import Instagram from '../components/Layout/Instagram/Instagram';
import ArticleHeader from '../components/Pages/ArticlePage/ArticleHeader/ArticleHeader';
import ArticlePage from '../components/Pages/ArticlePage/ArticlePage';

const Article = ({data}) => {
  const { t } = useTranslation();

  const crumbsNav = [
    {
      id: 1,
      title_ua: 'Головна',
      title_ru: 'Главная',
      title_en: 'Home',
      to: '/',
    },
    {
      id: 2,
      title_ua: 'Статті',
      title_ru: 'Статьи',
      title_en: 'Articles',
      to: '/articles/',
    },
  ];

  return (
    <Layout whitwHeader={'active'}>
      <Seo title={t('Home')} />
      <ArticleHeader
        mainData={data.strapiBlog}
        crumbsNav={crumbsNav}
      />
      <ArticlePage dataContent={data.strapiBlog.blog_content} dataShare={data.strapiBlog}/>
      <Instagram />
    </Layout>
  );
};

export default Article;

export const query = graphql`
  query ArticlesTemplateQuery($language: String!, $id: String!) {
    strapiBlog(id: { eq: $id }) {
    title_ua
    title_ru
    title_en
    slug
    data
    image {
      localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              layout: FULL_WIDTH
            )
          }
        }
    }
    blog_content
}

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
