import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";

import ShareSocial from '../AboutPage/ShareSocial/ShareSocial';
import useLanguage from '../../Hooks/useLanguage';

const ReactMarkdown = require("react-markdown");

const ArticlePage = ({dataContent, dataShare}) => {
  const langToggle = useLanguage;

  return (
    <article className="article-section">
      <div className="article-section__container">
       
        {dataContent.map((item, index) => {
          
          if(item.title_ua || item.title_ru || item.title_en) {
          return <div className="mark-down__block" key={index}><h2>{langToggle(item.title_ua, item.title_ru, item.title_en)}</h2></div>
          } else if(item.content_ua || item.content_ru || item.content_en) {
            return <ReactMarkdown key={index} source={langToggle(item.content_ua, item.content_ru, item.content_en)} className="mark-down__block"/> 
          } else if(item.image){
            return <div className="article-media" key={index}><LazyLoadImage effect="blur" src={`https://admin.oxx-clinic.com.ua${item.image.url}`} className="article-content__image" alt="blog-image"/></div>
          } else {
            return null;
          }
        })}

        <ShareSocial dataShare={dataShare}/>
      </div>
    </article>
  );
};

export default ArticlePage;
