import React from 'react';
import useLanguage from '../../../Hooks/useLanguage';
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  // TelegramShareButton,
  // ViberShareButton,
  // WhatsappShareButton,
} from 'react-share';

import Facebook from '../../../../svg/share_facebook.svg';
import Twitter from '../../../../svg/share_twitter.svg';
import Pinterest from '../../../../svg/share_pinterest.svg';
// import Telegram from '../../../../svg/telegram.svg';
// import Whatsapp from '../../../../svg/whatsapp.svg';
// import Viber from '../../../../svg/viber.svg';


const ShareSocial = ({dataShare}) => {
  const langToggle = useLanguage;
  const link = `https://oxx-clinic.com.ua/articles/${dataShare.slug}`;

  return (
    <footer className="article-footer">
      <span className="share-title">
        {langToggle('Поділитись:', 'Поделиться:', 'Share:')}
      </span>
      <ul className="share">
        <li className="share-item">
          <FacebookShareButton url={link}>
            <span className="share-item__link">
              <Facebook />
            </span>
          </FacebookShareButton>
        </li>
        <li className="share-item">
            <TwitterShareButton url={link}>
              <span className="share-item__link">
                <Twitter/>
              </span>
            </TwitterShareButton>
        </li>
        <li className="share-item">
          <PinterestShareButton url={link}>
              <span className="share-item__link">
                <Pinterest/>
              </span>
          </PinterestShareButton>
        </li>
        {/* <li className="share-item">
          <TelegramShareButton url={link}>
              <span className="share-item__link">
                <Telegram/>
              </span>
          </TelegramShareButton>
        </li>
        <li className="share-item">
          <WhatsappShareButton url={link}>
              <span className="share-item__link">
                <Whatsapp/>
              </span>
          </WhatsappShareButton>
        </li>
        <li className="share-item">
          <TelegramShareButton url={link}>
              <span className="share-item__link">
                <Telegram/>
              </span>
          </TelegramShareButton>
        </li> */}
      </ul>
    </footer>
  );
};

export default ShareSocial;
