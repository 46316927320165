import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from 'gatsby-plugin-react-i18next';
import useLanguage from '../../../Hooks/useLanguage';
import {fromNewSlugToUrl} from '../../../../utils/utils'

const ArticleHeader = ({ mainData, crumbsNav }) => {
  const langToggle = useLanguage;
  const image = getImage(mainData.image.localFile);

  return (
    <header id="scroller-top" className="article-header">
      <div className="article-header__bg">
      <GatsbyImage 
          image={image} 
          className="article-header__image" 
          alt={langToggle(
            mainData.title_ua,
            mainData.title_ru,
            mainData.title_en
          )}
      />
      </div>
      <div className="article-header__center">
        <span className="article-header__date">{mainData.data}</span>
        <h1 className="h1">
          {langToggle(
            mainData.title_ua,
            mainData.title_ru,
            mainData.title_en
          )}
        </h1>
        <span className="article-header__time">
          {langToggle('Читати', 'Читать', 'Read in')}{' '}
          <span>{langToggle('6 хв.', '6 мин.', '6 min')}</span>
        </span>
      </div>
      <div className="article-header__bottom">
        <ul className="crumbsNav">
          {crumbsNav.map((item, index) => {
            return (
              <li className="crumbsNav-item" key={index}>
                <Link className="crumbsNav-item__link" to={item.to}>
                  {langToggle(item.title_ua, item.title_ru, item.title_en)}
                </Link>
              </li>
            );
          })}
          <li className={mainData ? "crumbsNav-item" : null}>
              <Link className="crumbsNav-item__link" to={mainData ? fromNewSlugToUrl(mainData.slug) : null}>
                {mainData ? langToggle(mainData.title_ua, mainData.title_ru, mainData.title_en) : null}
              </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default ArticleHeader;
